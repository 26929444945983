<template>
  <div>
    <h2 class="headline--md text-bold text-uppercase">Update Crowdfunding Admin</h2>
    <div v-if="boUsers" class="box box--md box--white mt-16">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Email</p>
                <selection-box
                  class="mt-4"
                  selected-box-class="is-lg is-full search-box__filter"
                  v-model="params.adminId"
                  :options="boUserOptions"
                  :on-check="onSwitchAdminInfo"
                />
              </div>
            </td>
            <td>
              <p class="statistic__title text-warm">Full Name</p>
              <h2 class="headline--md mt-4">{{ params.fullName }}</h2>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Status</p>
                <label class="form-switch mt-4">
                  <input type="checkbox" v-model="params.status" />
                  <span></span>
                </label>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Wallet Address</p>
                <div class="form-group mt-4">
                  <input
                    type="text"
                    v-model="$v.params.address.$model"
                    :class="['form-control', { 'form-control--error': $v.params.address.$anyError }]"
                  />
                  <p v-if="$v.params.address.$anyError" class="form-error-text">Address can't be blank</p>
                </div>
              </div>
            </td>
            <td />
            <td />
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-end mt-16">
      <button class="btn btn--primary" @click="onOpenUpdateConfirm">Update</button>
    </div>

    <confirm-dialog
      v-if="onUpdateConfirmOpen"
      :open.sync="onUpdateConfirmOpen"
      confirmText="Are you sure ?"
      confirmButtonText="Save"
      confirmButtonClass="text-purple"
      @confirm="onUpdateCrowdfundingAdmin"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import withdrawAdminFormValidator from '@/validations/withdraw-admin-form'
import { STATUS } from '@/utils/key-mapping'
const SelectionBox = () => import('@/components/SelectionBox')
const ConfirmDialog = () => import('@/components/ConfirmDialog')

export default {
  data() {
    return {
      params: {
        address: '',
        adminId: 0,
        fullName: '',
        email: '',
        status: 1
      },
      onUpdateConfirmOpen: false,
      status: STATUS
    }
  },

  mixins: [withdrawAdminFormValidator],

  components: {
    SelectionBox,
    ConfirmDialog
  },

  computed: {
    ...mapState('boUsers', ['boUsers']),

    boUserOptions() {
      return this.boUsers.map(user => {
        return {
          value: user.id,
          name: user.email,
          disabled: false
        }
      })
    }
  },

  methods: {
    ...mapActions('boUsers', ['getBoUsers']),

    ...mapActions('requestTransfer', ['updateCrowdfundingAdmin', 'getCrowdfundingAdmin']),

    onSwitchAdminInfo() {
      const targetAdmin = this.boUsers.filter(user => user.id === this.params.admin_id)
      this.params.fullName = targetAdmin[0].fullName
      this.params.email = targetAdmin[0].email
    },

    getAdminDataRequest() {
      return {
        ...this.params,
        id: parseInt(this.$route.params.id),
        status: this.params.status ? this.status.active : this.status.deactive
      }
    },

    onOpenUpdateConfirm() {
      this.$v.$touch()
      if (!this.isFormValid) return

      this.onUpdateConfirmOpen = true
    },

    onUpdateCrowdfundingAdmin() {
      this.updateCrowdfundingAdmin(this.getAdminDataRequest())
        .then(() => {
          this.$router.push({ name: 'WithdrawAdmins' })
        })
        .catch(() => {
          this.onUpdateConfirmOpen = false
        })
    }
  },

  created() {
    this.getBoUsers()
    this.getCrowdfundingAdmin(this.$route.params.id).then(res => {
      const admin = res.data.result.data
      Object.keys(this.params).forEach(key => {
        this.params[key] = admin[key]
      })
      this.params.status = admin.status === this.status.active
    })
  }
}
</script>
